import React, { useState, useEffect } from 'react';
import './style.css';

const App = () => {
  const [offers, setOffers] = useState([]); // State to store API data
  const [showPopup, setShowPopup] = useState(false);
  const userCoins = 100; // Example user coins
  const userName = 'Testdaddy123'; // Example username

  useEffect(() => {
    // Function to fetch user's IP Address
    const fetchIPAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error('Error fetching IP Address: ', error);
        return '0.0.0.0'; // Fallback IP
      }
    };

    // Fetch data from the API
    const fetchData = async () => {
      try {
        const user_agent = navigator.userAgent;
        const response = await fetch('https://c92.me/offers.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ user_agent }),
        });
        const data = await response.json();
        setOffers(data.offers); // Update state with API data
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, []);

  const handleClick = () => {
    alert('hello world!');
  };

  // Function to handle Redeem button click
  const handleRedeemClick = () => {
    setShowPopup(true);
  };

  // Function to close the popup
  const closePopup = () => {
    setShowPopup(false);
  };

  // Render the popup based on userCoins
  const renderPopup = () => {
    if (userCoins < 1000) {
      return (
        <div className="popup-overlay">
          {' '}
          {/* Use popup-overlay class for overlay */}
          <div className="popup">
            {' '}
            {/* Popup box */}
            <p>You need to earn at least 1,000 coins to redeem!</p>
            <button className="close-btn" onClick={closePopup}>
              Close
            </button>{' '}
            {/* Close button with modern style */}
          </div>
        </div>
      );
    } else {
      return (
        <div className="popup-overlay">
          <div className="popup">
            <p>
              You have {userCoins} coins! In order to add {userCoins} to{' '}
              {userName}'s account, please verify you are human below. Coins
              will be available within 5 minutes.
            </p>
            <button className="close-btn" onClick={closePopup}>
              Close
            </button>
          </div>
        </div>
      );
    }
  };

  // Function to render content based on the active tab
  const renderContent = () => {
    return offers.map((offer) => (
      <div key={offer.offerid} className="offer" onClick={() => {}}>
        <img src={offer.picture} alt={offer.name} />
        <div className="offer-details">
          <p className="offer-name">{offer.name_short}</p>
          <p className="offer-description">{offer.adcopy}</p>
        </div>
        <button className="coin-button" onClick={handleClick}>
          <i className="fa fa-coins"></i> {offer.payout}
        </button>
      </div>
    ));
  };

  return (
    <div className="app">
      <header className="app-header">
        <div className="left_box"></div>
        <div className="header-center">GiveDice</div>
        <div className="right_box"></div>
      </header>
      <div className="offer-wall">{renderContent()}</div>
      <footer className="app-footer">
        <div className="tabs">
          <button className="tab" onClick={handleRedeemClick}>
            <i className="fa fa-hand-pointer"></i> Click Here To Redeem your{' '}
            {userCoins} Coins!
          </button>
        </div>
      </footer>
      {showPopup && renderPopup()}
    </div>
  );
};

export default App;
